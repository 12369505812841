.modal_width {
  max-width: 600px;
}

.modal_width_660 {
  max-width: 660px !important;
}

.modal_width_700 {
  max-width: 700px !important;
}

.container {
  padding: 50px 30px;
  margin-bottom: 0;
  .icon {
    fill: #118bf0 !important;
    width: 22px !important;
    height: 22px !important;
    margin-right: 1px;
  }
  .icon__container {
    margin-bottom: 50px;
  }

  .label {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }

  .details {
    font-size: 14px;
  }

  .form__content {
    margin: 30px 0;
  }

  .button__container {
    margin-top: 30px; // this should be 40px based on Jings docs, but most of the inputs got margin bottom 10px so setting this to 30px
    display: flex;

    .button {
      min-width: 77px;
      margin: 0;
    }

    .left__button {
      text-align: left;
      width: 50%;
    }

    .right__button {
      text-align: right;
      width: 50%;
    }
    .full__button {
      width: 100%;
    }
  }

  .button__container_column {
    display: flex;
    .rightSideReverse {
      width: 100%;
      justify-content: flex-end;
      flex-direction: row;
      display: flex;
    }
  }
}

.text__color {
  color: rgba(0, 0, 0, 0.65) !important;
}

.text__bold {
  font-weight: bold !important;
}

.large__text {
  font-size: 22px !important;
  color: rgb(74, 82, 95) !important;
}

.radio {
  width: 16px;
  height: 16px;
}

.disabled__input {
  background: #e9ecef !important;
  background-color: #e9ecef !important;
}

@media only screen and (max-width: 566px) {
  .button__container {
    text-align: center;
    button {
      margin: 0 !important;
      width: 100%;
      margin-top: 10px !important;
    }
  }
  .button__container_column {
    flex-direction: column;
    display: flex;
    .rightSideReverse {
      flex-direction: column-reverse !important;
      display: flex;
    }
  }
}
